import React from 'react';
import './Form.css';

const Form = () => {
    return (
        <iframe
            aria-label="sv-form-jul-12"
            src="https://forms.zohopublic.com/gomesluis479/form/svformjul12/formperma/64WqRwWFB1yFBpo08AN_cz7U9QGXZBNEybP3NmXa0Sk"
            title="Full Page Iframe"
        ></iframe>
    );
};

export default Form;
